var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"grey"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text-field, table, data-table, selection"}})],1):_c('v-card',[_c('snack-view',{attrs:{"message":_vm.snackbar.message,"activate":_vm.snackbar.active,"bg-color":_vm.snackbar.color}}),_c('div',[_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v(" Liste des clients ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","sm":"12","xl":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('v-select',{attrs:{"rules":_vm.LocalNameRules,"label":"Type de contrat","items":_vm.typeContrat,"item-text":"name","item-value":"id","outlined":"","dense":"","hide-details":"auto"},on:{"change":function($event){return _vm.contractChange()}},model:{value:(_vm.selectedContactGlobal),callback:function ($$v) {_vm.selectedContactGlobal=$$v},expression:"selectedContactGlobal"}})],1)],1)],1),_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Rechercher","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headers,"items":_vm.clients,"search":_vm.search,"options":_vm.dataOptions,"items-per-page":15,"sort-by":"id"},scopedSlots:_vm._u([{key:"item.num",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column "},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(_vm.clients.indexOf(item) + 1))])])])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(item.code))])])])]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column "},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"d-block text--primary"},[_vm._v(_vm._s(item.num_dist))])])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column "},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(item.email))]),_c('span',{staticClass:"d-block text--primary"},[_vm._v(_vm._s(item.tel))])])])]}},{key:"item.contrat",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column "},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(item.customer_segments.length))])])])]}},{key:"item.local",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column "},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(item.local))]),_c('span',{staticClass:"d-block font-weight-semibold text-truncate text--primary"},[_vm._v(_vm._s(item.customer_segments.map(function (obj) { return obj.local_count; }).reduce(function (accumulator, current) { return accumulator + current; }, 0)))])])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-12 w-"},[_c('v-btn',{staticClass:"ml-1 text-capitalize",attrs:{"outlined":"","small":"","color":"info"},on:{"click":function($event){return _vm.openDialog(item)}}},[_vm._v(" Visualiser les Contrats ")]),_c('v-btn',{staticClass:"ml-1 mt-1 text-capitalize",attrs:{"outlined":"","x-small":"","color":"#F06529"},on:{"click":function($event){return _vm.goToListLocaux(item)}}},[_c('v-icon',{staticClass:"me-1",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")]),_vm._v(" Liste des locaux ")],1),_c('v-btn',{staticClass:"ml-1 mt-1 text-capitalize",attrs:{"outlined":"","x-small":"","color":"#3a44ff"},on:{"click":function($event){return _vm.showUsers(item)}}},[_c('v-icon',{staticClass:"me-1",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")]),_vm._v(" Liste des utilisateurs ")],1),_c('v-btn',{staticClass:"ml-1 mt-1 text-capitalize",attrs:{"outlined":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.goToListBillsFille(item)}}},[_c('v-icon',{staticClass:"me-1",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")]),_vm._v(" Etats d'activation ")],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.dialogContrat),callback:function ($$v) {_vm.dialogContrat=$$v},expression:"dialogContrat"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Visualisation des contrats")])]),_c('v-divider'),_c('v-card-text',[_c('span',[_vm._v("Liste des contrats du client "+_vm._s(_vm.selectedLine.name))]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[[_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headersDetails,"items":_vm.details,"items-per-page":15,"sort-by":"id"},scopedSlots:_vm._u([{key:"item.num",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-bold text-truncate primary--text"},[_vm._v(_vm._s(_vm.details.indexOf(item) + 1))])])])]}},{key:"item.contrat",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-bold text-truncate primary--text"},[_vm._v(_vm._s(item.contrat))])])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-bold text-truncate primary--text"},[_vm._v(_vm._s(item.type))])])])]}}],null,true)})]],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.close}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.close}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
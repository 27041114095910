<template>
  <div>
    <v-sheet
      v-if='loading'
      :color="`grey`"
      class="pa-3"
    >
      <v-skeleton-loader
        class='mx-auto'
        type='text-field, table, data-table, selection'
      >
      </v-skeleton-loader>
    </v-sheet>

    <v-card v-else>
      <snack-view
        :message='snackbar.message'
        :activate='snackbar.active'
        :bg-color='snackbar.color'
      ></snack-view>
      <div>
        <v-card-title class='font-weight-bold'>
          Liste des clients
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="8"
              sm="12"
              xl="6"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-select
                    v-model='selectedContactGlobal'
                    :rules='LocalNameRules'
                    label='Type de contrat'
                    :items='typeContrat'
                    item-text='name'
                    item-value='id'
                    outlined
                    dense
                    hide-details='auto'
                    @change='contractChange()'
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-text-field
                v-model='search'
                :append-icon='icons.mdiMagnify'
                label='Rechercher'
                single-line
                hide-details
                dense
                outlined
              ></v-text-field>
          </v-row>
        </v-card-text>

        <!-- Table -->
        <v-data-table
          :headers="headers"
          :items="clients"
          :search="search"
          :options="dataOptions"
          :items-per-page="15"
          sort-by="id"
          class="table-kitchen-sink"
        >
          <!-- product -->
          <template #[`item.num`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ clients.indexOf(item) + 1}}</span>
              </div>
            </div>
          </template>

          <template #[`item.code`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.code }}</span>
              </div>
            </div>
          </template>

          <template #[`item.client`]='{item}'>
            <div class='d-flex align-center'>
              <div class='d-flex flex-column '>
                <span class='d-block font-weight-semibold text-truncate text--primary'>{{ item.name }}</span>
                <span class='d-block text--primary'>{{ item.num_dist }}</span>
              </div>
            </div>
          </template>

          <template #[`item.email`]='{item}'>
            <div class='d-flex align-center'>
              <div class='d-flex flex-column '>
                <span class='d-block font-weight-semibold text-truncate text--primary'>{{ item.email }}</span>
                <span class='d-block text--primary'>{{ item.tel }}</span>
              </div>
            </div>
          </template>

          <template #[`item.contrat`]='{item}'>
            <div class='d-flex align-center'>
              <div class='d-flex flex-column '>
                <span class='d-block font-weight-semibold text-truncate text--primary'>{{ item.customer_segments.length}}</span>
              </div>
            </div>
          </template>

          <template #[`item.local`]='{item}'>
            <div class='d-flex align-center'>
              <div class='d-flex flex-column '>
                <span class='d-block font-weight-semibold text-truncate text--primary'>{{ item.local }}</span>
                <span
                  class='d-block font-weight-semibold text-truncate text--primary'>{{ item.customer_segments.map(obj => obj.local_count).reduce((accumulator, current) => accumulator + current, 0)}}</span>
              </div>
            </div>
          </template>

          <template #[`item.actions`]="{item}">
            <div class="col-12 w-">
              <v-btn
                class="ml-1 text-capitalize"
                outlined
                small
                color="info"
                @click="openDialog(item)"
              >
                Visualiser les Contrats
              </v-btn>
              <v-btn
                outlined
                x-small
                color='#F06529'
                class='ml-1 mt-1 text-capitalize'
                @click='goToListLocaux(item)'
              >
                <v-icon
                  x-small
                  class='me-1'
                >
                  {{ icons.mdiEyeOutline }}
                </v-icon>
                Liste des locaux
              </v-btn>
              <v-btn
                outlined
                x-small
                color='#3a44ff'
                class='ml-1 mt-1 text-capitalize'
                @click='showUsers(item)'
              >
                <v-icon
                  x-small
                  class='me-1'
                >
                  {{ icons.mdiEyeOutline }}
                </v-icon>
                Liste des utilisateurs
              </v-btn>
              <v-btn
                outlined
                x-small
                color='primary'
                class='ml-1 mt-1 text-capitalize'
                @click='goToListBillsFille(item)'
              >
                <v-icon
                  x-small
                  class='me-1'
                >
                  {{ icons.mdiEyeOutline }}
                </v-icon>
                Etats d'activation
              </v-btn>
            </div>
          </template>
        </v-data-table>

        <!-- dialog contrats -->
        <v-dialog
          v-model='dialogContrat'
          max-width='500px'
          persistent
        >
          <v-card>
            <v-card-title>
              <span>Visualisation des contrats</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <span>Liste des contrats du client {{ selectedLine.name }}</span>

              <v-container>
                <v-row>
                  <v-col
                    cols='12'
                  >
                    <template>
                      <v-data-table
                        :headers='headersDetails'
                        :items='details'
                        :items-per-page='15'
                        sort-by='id'
                        class='table-kitchen-sink'
                      >
                        <template #[`item.num`]='{item}'>
                          <div class='d-flex align-center'>
                            <div class='d-flex flex-column ms-3'>
                              <span
                                class='d-block font-weight-bold text-truncate primary--text'>{{ details.indexOf(item) + 1
                                }}</span>
                            </div>
                          </div>
                        </template>

                        <template #[`item.contrat`]='{item}'>
                          <div class='d-flex align-center'>
                            <div class='d-flex flex-column ms-3'>
                              <span class='d-block font-weight-bold text-truncate primary--text'>{{ item.contrat
                                }}</span>
                            </div>
                          </div>
                        </template>

                        <template #[`item.type`]='{item}'>
                          <div class='d-flex align-center'>
                            <div class='flex-column ms-3'>
                              <span class='d-block font-weight-bold text-truncate primary--text'>{{ item.type }}</span>
                            </div>
                          </div>
                        </template>
                      </v-data-table>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color='primary'
                outlined

                @click='close'
              >
                Annuler
              </v-btn>
              <v-btn
                color='primary'
                @click='close'
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ref, onBeforeMount } from '@vue/composition-api'
import data from '@/views/locaux/datatable'
import Http from '@/helpers/http'
import Vue from 'vue'
import moment from 'moment'
import SnackView from '@/views/SnackView'
import router from '@/router'
Vue.prototype.moment = moment

export default {
  components: {
    SnackView,
  },
  setup() {
    const search = ref('')
    const clients = ref([])
    const details = []
    let loading = ref(true)
    const snackbar = ref({
      active: false,
      message: '',
      color: 'success',
    })
    // Règle de validation d'un champ
    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]
    const orderNum = item => clients.value.indexOf(item) + 1
    const formatDate = date => moment(date).format('MM-DD-YYYY')
    const typeContrat = ref([])
    const segment = ref([])

    const loadTypeContrat = () => {
      Http.get('customer-type')
        .then(async response => {
          if (response.status === 200) {
            // eslint-disable-next-line no-use-before-define
            typeContrat.value = response.data
            typeContrat.value.push({
              id: -1,
              name: 'Tous',
              created_at: '2022-06-14T11:07:50.000000Z',
              updated_at: '2022-06-23T10:54:42.000000Z',
            })
            console.log('typeContrats')
            // eslint-disable-next-line no-use-before-define
            console.log('contrat', typeContrat.value)
          }
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }
    const loadSegments = () => {
      Http.get('segment')
        .then(async response => {
          if (response.status === 200) {
            // eslint-disable-next-line no-use-before-define
            segment.value = response.data
            segment.value.push({
              id: -1,
              name: 'Tous',
              created_at: '2022-06-14T11:07:50.000000Z',
              updated_at: '2022-06-23T10:54:42.000000Z',
            })
            console.log('segments')
            // eslint-disable-next-line no-use-before-define
            console.log(segment.value)
          }
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    onBeforeMount(() => {
      loadTypeContrat()
      loadSegments()
    })
    const selectedLine = ref([])
    return {
      icons: {
      },
      search,
      LocalNameRules,
      loadTypeContrat,
      orderNum,
      details,
      typeContrat,
      segment ,
      loading,
      selectedLine,
      dialogContrat: false,
      formatDate,
      selectedContactGlobal: null,
      headers: [
        { text: 'Num ', value: 'num', divider: true, align: 'center' },
        { text: "Code", value: 'code', divider: true, align: 'center' },
        { text: 'Nom Client', value: 'client', divider: true },
        { text: 'E-mail/Téléphone', value: 'email', divider: true },
        { text: 'Contrat', value: 'contrat', divider: true, align: 'center' },
        { text: 'Local', value: 'local', divider: true, align: 'center' },
        {
          text: 'Actions', value: 'actions', sortable: false, divider: true, align: 'center',
        },
      ],

      headersDetails: [
        {
          text: 'N°', align: 'start', sortable: false, value: 'num', divider: true,
        },
        { text: 'Contrat', value: 'contrat', divider: true },
        {
          text: 'Type', value: 'type', divider: true, align: 'center',
        },
      ],
      dataOptions: {
        sortBy: ['id'],
      },
      clients,
      defaultItem: {},
      snackbar,
    }
  },

  mounted() {
    this.loading = true
    this.loadClients()
  },

  methods: {
    initialize() {
      this.ClientFilleList = JSON.parse(JSON.stringify(data))
    },

    loadClients() {
      this.loading = true
      let parentId = JSON.parse(localStorage.getItem('customer')).id
      Http.post('get-all-child', {parentId})
        .then(async response => {
          if (response.status === 200) {
            this.clients = await response.data
            console.log(this.clients)
          }
        })
        .catch(error => {
          console.error('Erreur :', error)
        }).finally(()=> {
        this.loading = false
        console.log('loading', this.loading)
      })
    },

    showUsers(item) {
      this.selectedLine = item
      localStorage.setItem("clientFilleId", JSON.stringify({ selectedLine: this.selectedLine }))
      let storedData = JSON.parse(localStorage.getItem("clientFilleId"))
      let customerId = storedData.selectedLine
      console.log("id user", customerId)
      router.push({ name: 'list-usersFille', params: { customerId: this.selectedLine.id, data: [] } })
    },
    goToListBillsFille(item) {
      this.selectedLine = item
      localStorage.setItem("clientFilleId", JSON.stringify({ selectedLine: this.selectedLine }))
      let storedData = JSON.parse(localStorage.getItem("clientFilleId"))
      //let contratType = storedData.selectedLine.customer_segments[0].id
      let customerId = storedData.selectedLine.id
      console.log("id user", customerId)
      router.push({ name: 'list-billsFille', params: { customerId: this.selectedLine.id, data: [] } })
    },

    goToListLocaux(item) {

      this.selectedLine = item
      localStorage.setItem("clientFilleId", JSON.stringify({ selectedLine: this.selectedLine }))
      let storedData = JSON.parse(localStorage.getItem("clientFilleId"))
      let localId = storedData.selectedLine.id
      let contratType = storedData.selectedLine.customer_segments[0].id
      console.log("id contrat", contratType)
      console.log("id local", localId)

        router.push({ name: 'list-emplacementFille', params: { id: this.localId, data: [] } })
    },


    close() {
      this.dialogContrat = false
    },

    openDialog(item) {
      this.selectedLine = item
      const { customer_segments } = item
      const listDetails = ref([])

      for (let i = 0; i < customer_segments.length; i++) {
        console.log(customer_segments[i])
        listDetails.value.push(
          {
            contrat: customer_segments[i].categorie.name,
            type: customer_segments[i].customer_type.name,
          },
        )
      }
      console.log(listDetails.value)
      this.details = listDetails.value
      console.log(this.details)
      this.dialogContrat = true
    },

    loadClientsFilter(type) {
      this.loading = true
      let contractType
      if (type !== undefined && type !== -1) {
        contractType = type
      }

      let parentId = JSON.parse(localStorage.getItem('customer')).id
      Http.post('get-all-child', { contractType, parentId })
        .then(async response => {
          if (response.status === 200) {
            this.loading = false
            this.clients = await response.data
            console.log(this.clients)
          }
        })
        .catch(error => {
          this.loading = false
          console.error('Erreur :', error)
        })
    },

    contractChange() {
      this.loadClientsFilter(this.selectedContactGlobal)
    },
  },
}
</script>
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
